import React from 'react'
import { useSession } from 'src/sdk/session'
import { useBuyQuizContext } from 'src/contexts/buy-quiz-context'
import { useQuizEvent } from 'src/sdk/analytics/hooks/useQuizEvent'
import { MaleIcon, FemaleIcon } from 'src/images/buy-quiz/icons'

const FirstQuestion = () => {
  const { person } = useSession()
  const { dispatch } = useBuyQuizContext()
  const { sendQuizClickEvent } = useQuizEvent()

  const handleClick = (heOrShe: 'Ele' | 'Ela') => {
    dispatch({ payload: { property: 'heOrShe', value: heOrShe } })
  }

  return (
    <div className="firstQuestion-container">
      <span className="firstQuestion-question">Quem você vai presentear?</span>
      <div className="firstQuestion-optionsContainer">
        <button
          className="firstQuestion-option"
          onClick={() => {
            handleClick('Ela')
            sendQuizClickEvent({
              quiz_category: 'quiz_natal',
              quiz_answer: 'Ela',
              quiz_step: 'pergunta_01',
              user_logged: !!person?.id,
              user_id: person?.id ?? null,
            })
          }}
        >
          <FemaleIcon />
          <span className="firstQuestion-option-span">Ela</span>
        </button>
        <button
          className="firstQuestion-option"
          onClick={() => {
            handleClick('Ele')
            sendQuizClickEvent({
              quiz_category: 'quiz_natal',
              quiz_answer: 'Ele',
              quiz_step: 'pergunta_01',
              user_logged: !!person?.id,
              user_id: person?.id ?? null,
            })
          }}
        >
          <MaleIcon />
          <span className="firstQuestion-option-span">Ele</span>
        </button>
      </div>
    </div>
  )
}

export default FirstQuestion
