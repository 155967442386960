import React, { useState } from 'react'
import { useSession } from 'src/sdk/session'
import { useBuyQuizContext } from 'src/contexts/buy-quiz-context'
import Button from 'src/components/ui/Button/Button'
import Spinner from 'src/components/common/Spinner/Spinner'
import { useQuizEvent } from 'src/sdk/analytics/hooks/useQuizEvent'
import { hashData } from 'src/utils/hashData'

import AgePolicyCheckbox from './AgePolicyCheckbox'
import { saveQuizData } from './saveQuizData'

const REGEX_VALIDATE_EMAIL =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const EmailQuestion = () => {
  const [ageSelected, setAgeSelected] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [inputEmail, setInputEmail] = useState<string | null>(null)
  const { person } = useSession()
  const { sendQuizClickEvent } = useQuizEvent()
  const { quizState, dispatch } = useBuyQuizContext()

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()

    setInputEmail(event.target.value)
  }

  const handleClick = async () => {
    if (
      inputEmail &&
      REGEX_VALIDATE_EMAIL.test(inputEmail) &&
      !isLoading &&
      ageSelected
    ) {
      const hashedName = await hashData(quizState.name as string)
      const hashedEmail = await hashData(inputEmail)

      setIsLoading(true)

      saveQuizData({
        ...quizState,
        olderThanEighteen: ageSelected,
        email: inputEmail,
      })
        .then(() => {
          setIsLoading(false)

          dispatch({
            payload: { property: 'olderThanEighteen', value: ageSelected },
          })

          dispatch({
            payload: { property: 'email', value: inputEmail },
          })

          sendQuizClickEvent({
            quiz_category: 'quiz_natal',
            quiz_answer: null,
            quiz_step: 'qual_o_seu_email_step_08',
            quiz_user_name: hashedName,
            quiz_user_email: hashedEmail,
            user_logged: !!person?.id,
            user_id: person?.id ?? null,
          })
        })
        .finally(() => setIsLoading(false))
    }
  }

  return (
    <div className="emailQuestion-container">
      <span className="emailQuestion-question">Qual o seu email?</span>
      <input
        className="emailQuestion-input"
        type="text"
        placeholder="Seu e-mail"
        value={inputEmail ?? ''}
        onChange={handleInput}
      />
      <AgePolicyCheckbox
        setAgeSelected={setAgeSelected}
        ageSelected={ageSelected}
      />
      <Button
        variant="primary"
        onClick={handleClick}
        disabled={!REGEX_VALIDATE_EMAIL.test(inputEmail ?? '') || !ageSelected}
      >
        {isLoading ? <Spinner /> : 'Ver o resultado'}
      </Button>
    </div>
  )
}
