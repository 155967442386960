import React, { useState } from 'react'
import { useSession } from 'src/sdk/session'
import { useBuyQuizContext } from 'src/contexts/buy-quiz-context'
import { useQuizEvent } from 'src/sdk/analytics/hooks/useQuizEvent'
import Button from 'src/components/ui/Button/Button'
import { hashData } from 'src/utils/hashData'

import { saveQuizData } from './saveQuizData'
import { setAgeOnCL } from './AgePolicyModal'
import AgePolicyCheckbox from './AgePolicyCheckbox'

export const NameQuestion = () => {
  const { person } = useSession()
  const { quizState, dispatch } = useBuyQuizContext()
  const { sendQuizClickEvent } = useQuizEvent()
  const { email } = quizState
  const [ageSelected, setAgeSelected] = useState(!email)
  const [inputName, setInputName] = useState('')

  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()

    setInputName(event.target.value)
  }

  const handleSubmit = async () => {
    const hashedName = await hashData(inputName)

    dispatch({ payload: { property: 'name', value: inputName } })

    sendQuizClickEvent({
      quiz_category: 'quiz_natal',
      quiz_answer: null,
      quiz_step: 'como_podemos_te_chamar_step_07',
      quiz_user_name: hashedName,
      user_logged: !!person?.id,
      user_id: person?.id ?? null,
    })

    if (!email) {
      return
    }

    dispatch({ payload: { property: 'olderThanEighteen', value: ageSelected } })
    setAgeOnCL(ageSelected)

    saveQuizData({
      ...quizState,
      name: hashedName,
      olderThanEighteen: ageSelected,
    })
  }

  return (
    <div className="NameQuestion-container">
      <span className="NameQuestion-question">Como podemos te chamar?</span>

      <input
        onChange={handleChangeName}
        placeholder="Seu nome ou apelido"
        className="NameQuestion-input"
        type="text"
        name="NameQuestion-option"
        id="NameQuestion-option1"
      />

      {email && (
        <AgePolicyCheckbox
          setAgeSelected={setAgeSelected}
          ageSelected={ageSelected}
        />
      )}

      <Button
        variant="primary"
        className={`NameQuestion-button NameQuestion-button-${
          inputName === '' || !ageSelected ? 'disabled' : 'enabled'
        }`}
        disabled={inputName === '' || !ageSelected}
        onClick={handleSubmit}
      >
        <span className="NameQuestion-button-label">Prosseguir</span>
      </Button>
    </div>
  )
}
