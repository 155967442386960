import React, { useState } from 'react'
import { useSession } from 'src/sdk/session'
import { useBuyQuizContext } from 'src/contexts/buy-quiz-context'
import { useQuizEvent } from 'src/sdk/analytics/hooks/useQuizEvent'
import Button from 'src/components/ui/Button/Button'

import { ButtonArrow } from '../../../images/buy-quiz/icons'

const ThirdQuestion = () => {
  const [option, setOption] = useState<string>('')
  const { person } = useSession()
  const { dispatch, quizState } = useBuyQuizContext()
  const { sendQuizClickEvent } = useQuizEvent()
  const { heOrShe } = quizState

  const handleClick = (ageGroup: string) => {
    setOption(ageGroup)
  }

  const handleSubmit = () => {
    dispatch({ payload: { property: 'ageGroup', value: option } })

    sendQuizClickEvent({
      quiz_category: 'quiz_natal',
      quiz_answer: option,
      quiz_step: heOrShe === 'Ele' ? 'pergunta_03_ele' : 'pergunta_03_ela',
      user_logged: !!person?.id,
      user_id: person?.id ?? null,
    })

    if (option === '0-3' || option === '4-10' || option === '11-15') {
      dispatch({
        payload: {
          property: 'style',
          value:
            option === '0-3' ? 'Baby' : option === '4-10' ? 'Kids' : 'Teen',
        },
      })
    }
  }

  const { giftedPerson } = quizState
  const shouldRenderLowAges = [
    'Filha / Sobrinha / Afilhada',
    'Filho / Sobrinho / Afilhado',
  ].includes(giftedPerson as string)

  return (
    <div className="thirdQuestion-container">
      <span className="thirdQuestion-question">Qual a faixa etária?</span>
      <div className="thirdQuestion-optionsContainer">
        <div className="thirdQuestion-optionsContainer-options">
          {shouldRenderLowAges && (
            <>
              <span className="thirdQuestion-option-span">
                <input
                  className="thirdQuestion-radio"
                  type="radio"
                  name="thirdQuestion-option"
                  id="thirdQuestion-option1"
                  onClick={() => handleClick('0-3')}
                />
                <label
                  htmlFor="thirdQuestion-option1"
                  className="thirdQuestion-option-span-label"
                >
                  0 a 3 anos
                </label>
              </span>
              <span className="thirdQuestion-option-span">
                <input
                  className="thirdQuestion-radio"
                  type="radio"
                  name="thirdQuestion-option"
                  id="thirdQuestion-option2"
                  onClick={() => handleClick('4-10')}
                />
                <label
                  htmlFor="thirdQuestion-option2"
                  className="thirdQuestion-option-span-label"
                >
                  4 a 10 anos
                </label>
              </span>
              <span className="thirdQuestion-option-span">
                <input
                  className="thirdQuestion-radio"
                  type="radio"
                  name="thirdQuestion-option"
                  id="thirdQuestion-option3"
                  onClick={() => handleClick('11-15')}
                />
                <label
                  htmlFor="thirdQuestion-option3"
                  className="thirdQuestion-option-span-label"
                >
                  11 a 15 anos
                </label>
              </span>
            </>
          )}
          <span className="thirdQuestion-option-span">
            <input
              className="thirdQuestion-radio"
              type="radio"
              name="thirdQuestion-option"
              id="thirdQuestion-option4"
              onClick={() => handleClick('16-35')}
            />
            <label
              htmlFor="thirdQuestion-option4"
              className="thirdQuestion-option-span-label"
            >
              16 a 35 anos
            </label>
          </span>
          <span className="thirdQuestion-option-span">
            <input
              className="thirdQuestion-radio"
              type="radio"
              name="thirdQuestion-option"
              id="thirdQuestion-option5"
              onClick={() => handleClick('36-50')}
            />
            <label
              htmlFor="thirdQuestion-option5"
              className="thirdQuestion-option-span-label"
            >
              36 a 50 anos
            </label>
          </span>
          {!shouldRenderLowAges && (
            <>
              <span className="thirdQuestion-option-span">
                <input
                  className="thirdQuestion-radio"
                  type="radio"
                  name="thirdQuestion-option"
                  id="thirdQuestion-option6"
                  onClick={() => handleClick('>50')}
                />
                <label
                  htmlFor="thirdQuestion-option6"
                  className="thirdQuestion-option-span-label"
                >
                  Acima de 50 anos
                </label>
              </span>
            </>
          )}
        </div>
        <Button
          variant="primary"
          icon={<ButtonArrow />}
          iconPosition="right"
          disabled={option === ''}
          onClick={() => handleSubmit()}
          className="button thirdQuestion-submit"
        >
          <span className="thirdQuestion-submit-span">Prosseguir</span>
        </Button>
      </div>
    </div>
  )
}

export default ThirdQuestion
