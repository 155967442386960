import React from 'react'
import { useSession } from 'src/sdk/session'
import { useBuyQuizContext } from 'src/contexts/buy-quiz-context'
import { useQuizEvent } from 'src/sdk/analytics/hooks/useQuizEvent'

import { AgePolicyModal } from '../DataValidate/AgePolicyModal'
import { saveQuizData } from '../DataValidate/saveQuizData'

const buttons = [
  {
    label: 'até R$300',
    value: '0:300',
  },
  {
    label: 'de R$300 a R$500',
    value: '300:500',
  },
  {
    label: 'de R$500 a R$1000',
    value: '500:1000',
  },
  {
    label: 'de R$ 1000 a R$ 2500',
    value: '1000:2500',
  },
  {
    label: 'acima de R$ 2500',
    value: '2500:999999',
  },
]

export const FifthQuestion = () => {
  const { person } = useSession()
  const { quizState, dispatch } = useBuyQuizContext()
  const { sendQuizClickEvent } = useQuizEvent()

  const {
    name,
    email,
    olderThanEighteen,
    priceRange,
    heOrShe,
    giftedPerson,
    ageGroup,
  } = quizState

  const openAgeModal = name && email && priceRange && !olderThanEighteen
  const shouldRenderFirstRange = !(
    giftedPerson?.includes('Filh') &&
    ((heOrShe === 'Ele' && ageGroup === '11-15') || ageGroup === '0-3')
  )

  const handleClick = (value: string) => {
    dispatch({ payload: { property: 'priceRange', value } })

    sendQuizClickEvent({
      quiz_category: 'quiz_natal',
      quiz_answer: value,
      quiz_step: heOrShe === 'Ele' ? 'pergunta_05_ele' : 'pergunta_05_ela',
      user_logged: !!person?.id,
      user_id: person?.id ?? null,
    })

    if (name && email && olderThanEighteen) {
      saveQuizData({ ...quizState, priceRange: value })
    }
  }

  return (
    <>
      <div className="fifthQuestion-question-container">
        <span className="fifthQuestion-question">
          Qual a faixa de preço do presente?
        </span>
        <div className="fifthQuestion-options">
          {buttons.map(
            (button) =>
              (button.value !== '0:300' || shouldRenderFirstRange) && (
                <button
                  className="fifthQuestion-options-button"
                  key={button.label}
                  onClick={() => handleClick(button.value)}
                >
                  {button.label}
                </button>
              )
          )}
        </div>
      </div>
      {openAgeModal && <AgePolicyModal />}
    </>
  )
}
